import { app } from "@/main";
import { PaymentFilterMenuSelection } from "@/models/payments/payment-filter-menu-selection";
import { PaymentsServiceInterface } from "@/models/payments/payments-service.interface";
import { SettlementRunActivityFiltersResponse } from "@/models/payments/settlement-run-activity/settlement-run-activity-filters-response";
import { SettlementRunActivityResponse } from "@/models/payments/settlement-run-activity/settlement-run-activity-response";
import { SettlementRunPaymentDetail } from "@/models/payments/settlement-run-activity/settlement-run-payment-details";
import { CancelSettlementRunResponse } from "@/models/payments/settlement-run-activity/cancel-settlement-run-response";
import { CancelSettlementRunRequest } from "@/models/payments/settlement-run-activity/cancel-settlement-run-request";

class SettlementRunActivityService implements PaymentsServiceInterface {
  private baseURL = "api/payments/settlements";

  private buildQueryParameters(
    filters: PaymentFilterMenuSelection,
    page?: number,
    pageSize?: number
  ): string {
    let query = `?begin_date=${filters.startDate}&end_date=${filters.endDate}`;
    if (filters.firstItemSelection != "") {
      // eslint-disable-next-line
      query += `&company_id=${encodeURIComponent(filters.firstItemSelection)}`;
    }
    if (filters.secondItemSelection != "") {
      // eslint-disable-next-line
      query += `&payment_type=${encodeURIComponent(filters.secondItemSelection)}`;
    }
    if (filters.thirdItemSelection != "") {
      // eslint-disable-next-line
      query += `&payment_file_status_type=${encodeURIComponent(filters.thirdItemSelection)}`;
    }
    if (page) {
      query += `&page=${page}`;
    }
    query += pageSize ? `&page_size=${pageSize}` : "&page_size=10";
    return query;
  }

  public async get(
    filters: PaymentFilterMenuSelection,
    page = 1,
    pageSize?: number
  ): Promise<SettlementRunActivityResponse> {
    const query = this.buildQueryParameters(filters, page, pageSize);
    const apiResponse = await app.$ravenapi.get(this.baseURL.concat(query));
    return apiResponse?.data;
  }

  public async getFilters(): Promise<SettlementRunActivityFiltersResponse> {
    const apiResponse = await app.$ravenapi.get(
      this.baseURL.concat("/filters")
    );
    return apiResponse?.data;
  }

  public async getDetails(uuid: string): Promise<SettlementRunPaymentDetail[]> {
    const apiResponse = await app.$ravenapi.get(
      this.baseURL.concat("/payments").concat(`?payment_file_uuid=${uuid}`)
    );
    return apiResponse?.data?.data ?? [];
  }

  public async cancelSettlementRun(
    cancelSettlementRunRequest: CancelSettlementRunRequest
  ): Promise<CancelSettlementRunResponse> {
    const apiResponse = await app.$ravenapi.post(
      this.baseURL
        .concat(`/uuid/${cancelSettlementRunRequest.uuid}`)
        .concat("?action=cancel"),
      cancelSettlementRunRequest
    );
    return apiResponse?.data;
  }
}

export const settlementRunActivityService = new SettlementRunActivityService();
