





















































































































































import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { cloneDeep } from "lodash";
import { utils } from "@/utils/okta-utils";
import Catch from "@/shared/decorators/catch-errors";
import options from "@/shared/constants/toast-options";
import TableColumnSelectorModal from "@/components/payments/TableColumnSelectorModal.vue";
import PaymentsFilterMenu from "@/components/payments/PaymentsFilterMenu.vue";
import IconButton from "@/components/design-system/buttons/IconButton.vue";
import IconTextButton from "@/components/design-system/buttons/IconTextButton.vue";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import ExportResultsModal from "@/components/payments/ExportResultsModal.vue";
import SettlementRunDetails from "@/components/payments/SettlementRunDetails.vue";
import SettlementRunActivityStore from "@/store/payments/settlement-run-activity.store";
import UserStore from "@/store/user.store";
import { downloadCsvFile } from "@/helpers/csv-file-helper";
import { settlementRunActivityService } from "@/services/payments/settlement-run-activity.service";
import { TableColumnSelector } from "@/models/payments/table-column-selector";
import { SettlementRunActivityRecord } from "@/models/payments/settlement-run-activity/settlement-run-activity-record";
import { InputObject } from "@/models/document-entry/input-object";
import { PaymentFilterMenuSelection } from "@/models/payments/payment-filter-menu-selection";
import { SettlementRunActivityFiltersResponse } from "@/models/payments/settlement-run-activity/settlement-run-activity-filters-response";
import { PaymentPaginationMetadata } from "@/models/payments/payment-pagination-metadata";
import { formatMonetaryValue } from "@/helpers/decimal-formatting-helper";

@Component({
  components: {
    "icon-button": IconButton,
    "icon-text-button": IconTextButton,
    "table-column-selector-modal": TableColumnSelectorModal,
    "payments-filter-menu": PaymentsFilterMenu,
    "primary-button": PrimaryButton,
    "export-results-modal": ExportResultsModal,
    "settlement-run-details": SettlementRunDetails
  }
})
export default class SettlementRunActivity extends Vue {
  // reactive class properties

  private isLoadingTableData = false;
  private isLoadingTableFilters = false;
  private isColumnModalDisplayed = false;
  private searchFilter = "";
  private isExportInProgress = false;
  private isExportResultsModalVisible = false;
  private fileToBeDownloadedName = "";

  private readonly allCompanyOption: InputObject = {
    text: "All Companies",
    value: ""
  };
  private readonly allPaymentTypesOption: InputObject = {
    text: "All Payment Types",
    value: ""
  };
  private readonly allSettlementRunStatusesOption: InputObject = {
    text: "All SR Statuses",
    value: ""
  };

  // readonly store modules

  private readonly settlementRunActivityStore: SettlementRunActivityStore = getModule(
    SettlementRunActivityStore,
    this.$store
  );

  private readonly userStore: UserStore = getModule(UserStore, this.$store);

  // computed properties

  private get customerID(): number | null {
    return this.settlementRunActivityStore.getCustomerID;
  }

  private get defaultHeaders(): TableColumnSelector[] {
    return this.settlementRunActivityStore.getDefaultHeaders;
  }
  private get availableHeaders(): TableColumnSelector[] {
    return this.settlementRunActivityStore.getAvailableHeaders;
  }
  private get headersToDisplay(): TableColumnSelector[] {
    return this.shouldShowSettlementRunDetails
      ? [
          ...this.availableHeaders.filter(column => column.selected),
          {
            align: "start",
            text: "Details",
            sortable: false,
            value: "payment_file_uuid",
            selected: false
          }
        ]
      : this.availableHeaders.filter(column => column.selected);
  }

  private get settlementRuns(): SettlementRunActivityRecord[] {
    return this.settlementRunActivityStore.getSettlementRuns;
  }
  private get selectedSettlementRun(): SettlementRunActivityRecord | null {
    return this.settlementRunActivityStore.getSelectedSettlementRun;
  }

  private get storedStartDate(): string {
    return this.settlementRunActivityStore.getStartDate;
  }
  private get storedEndDate(): string {
    return this.settlementRunActivityStore.getEndDate;
  }
  private get storedCompanyOptions(): InputObject[] {
    return this.settlementRunActivityStore.getCompanyOptions;
  }
  private get storedPaymentTypeOptions(): InputObject[] {
    return this.settlementRunActivityStore.getPaymentTypeOptions;
  }
  private get storedSettlementRunStatusOptions(): InputObject[] {
    return this.settlementRunActivityStore.getSettlementRunStatusOptions;
  }
  private get companyOptions(): InputObject[] {
    return [this.allCompanyOption, ...this.storedCompanyOptions];
  }
  private get selectedCompany(): string | null {
    return (
      this.settlementRunActivityStore.getSelectedCompany ??
      (this.allCompanyOption.value as string)
    );
  }
  private get paymentTypeOptions(): InputObject[] {
    return [this.allPaymentTypesOption, ...this.storedPaymentTypeOptions];
  }
  private get selectedPaymentType(): string | null {
    return (
      this.settlementRunActivityStore.getSelectedPaymentType ??
      (this.allPaymentTypesOption.value as string)
    );
  }
  private get settlementRunStatusOptions(): InputObject[] {
    return [
      this.allSettlementRunStatusesOption,
      ...this.storedSettlementRunStatusOptions
    ];
  }
  private get selectedSettlementRunStatus(): string | null {
    return (
      this.settlementRunActivityStore.getSelectedSettlementRunStatus ??
      (this.allSettlementRunStatusesOption.value as string)
    );
  }

  private get paginationMetadata(): PaymentPaginationMetadata | null {
    return this.settlementRunActivityStore.getPaginationMetadata;
  }
  private get pagesLoaded(): number[] {
    return this.settlementRunActivityStore.getPagesLoaded;
  }
  private get currentPage(): number {
    return this.paginationMetadata?.page ?? 1;
  }
  private get itemsStart(): number {
    return (
      (this.paginationMetadata?.page ?? 1) *
        (this.paginationMetadata?.page_size ?? 0) -
      (this.paginationMetadata?.page_size ?? 0) +
      1
    );
  }
  private get totalPages(): number {
    return this.paginationMetadata?.total_pages ?? 0;
  }
  private get itemsStop(): number {
    if (
      this.totalItems < this.pageSize ||
      (this.paginationMetadata?.page ?? 1) *
        (this.paginationMetadata?.page_size ?? 0) >
        this.totalItems
    ) {
      return this.totalItems;
    } else {
      return (
        (this.paginationMetadata?.page ?? 1) *
        (this.paginationMetadata?.page_size ?? 0)
      );
    }
  }
  private get totalItems(): number {
    return this.paginationMetadata?.total_results ?? 0;
  }
  private get pageSize(): number {
    return this.paginationMetadata?.page_size ?? 10;
  }

  private get isExportButtonDisabled(): boolean {
    return this.settlementRuns.length == 0 || this.isExportInProgress;
  }

  private get shouldShowSettlementRunDetails(): boolean {
    return (
      this.$launchDarkly.variation(
        "should-show-settlement-run-details",
        false
      ) ?? false
    );
  }

  // lifecycle methods

  async created() {
    // check that current stored data is for current customer
    // this only applies to internal users that toggle between customers
    if (this.customerID != this.userStore.getSelectedCustomer?.id) {
      await this.settlementRunActivityStore.resetState();
    }
    this.settlementRunActivityStore.setCustomer(
      this.userStore.getSelectedCustomer?.id
    );
    // make API request to load filter menu options if no filters are stored in vuex
    if (
      this.storedCompanyOptions.length == 0 ||
      this.storedSettlementRunStatusOptions.length == 0 ||
      this.storedPaymentTypeOptions.length == 0
    ) {
      this.isLoadingTableFilters = true;
      const filters: SettlementRunActivityFiltersResponse = await settlementRunActivityService.getFilters();
      if (filters?.company_name) {
        this.settlementRunActivityStore.setCompanyOptions(filters.company_name);
      }
      if (filters?.payment_file_status_type) {
        this.settlementRunActivityStore.setSettlementRunStatusOptions(
          filters.payment_file_status_type
        );
      }
      if (filters?.payment_type) {
        this.settlementRunActivityStore.setPaymentTypeOptions(
          filters.payment_type
        );
      }
      this.isLoadingTableFilters = false;
    }

    if (this.settlementRuns.length == 0) {
      await this.loadTableData(
        new PaymentFilterMenuSelection(
          this.selectedCompany as string,
          this.selectedPaymentType as string,
          this.selectedSettlementRunStatus as string,
          this.storedStartDate,
          this.storedEndDate
        ),
        1
      );
    }
  }

  // methods
  handleTableColumnFiltersModal() {
    this.isColumnModalDisplayed = !this.isColumnModalDisplayed;
  }

  handleColumnUpdate(updatedColumns: TableColumnSelector[]) {
    this.settlementRunActivityStore.setAvailableHeaders(
      cloneDeep(updatedColumns)
    );
    this.handleTableColumnFiltersModal();
  }

  async handleFilterChange(filters: PaymentFilterMenuSelection) {
    // store selected filters so that they remain set upon subsequent visits to this page
    this.settlementRunActivityStore.setSelectedCompany(
      filters.firstItemSelection
    );
    this.settlementRunActivityStore.setSelectedPaymentType(
      filters.secondItemSelection
    );
    this.settlementRunActivityStore.setSelectedSettlementRunStatus(
      filters.thirdItemSelection
    );
    this.settlementRunActivityStore.setStartDate(filters.startDate);
    this.settlementRunActivityStore.setEndDate(filters.endDate);

    await this.loadTableData(filters, 1);
  }

  async loadTableData(filters: PaymentFilterMenuSelection, page?: number) {
    // make API request for settlement run activity data related to selected filters
    this.isLoadingTableData = true;
    const settlementRuns = await settlementRunActivityService.get(
      filters,
      page
    );
    if (page && page > 1) {
      this.settlementRunActivityStore.setSettlementRuns([
        ...this.settlementRuns,
        ...settlementRuns.data
      ]);
      this.settlementRunActivityStore.setPagesLoaded([
        ...this.pagesLoaded,
        page
      ]);
    } else {
      this.settlementRunActivityStore.setSettlementRuns(settlementRuns.data);
      this.settlementRunActivityStore.setPagesLoaded([1]);
    }
    this.settlementRunActivityStore.setPaginationMetadata(
      settlementRuns.metadata
    );
    this.isLoadingTableData = false;
  }

  async handleNavigateToNextPage() {
    if (this.pagesLoaded.includes(this.currentPage + 1)) {
      this.settlementRunActivityStore.setPaginationMetadata({
        ...(this.paginationMetadata as PaymentPaginationMetadata),
        page: this.currentPage + 1
      });
    } else {
      await this.loadTableData(
        new PaymentFilterMenuSelection(
          this.selectedCompany as string,
          this.selectedPaymentType as string,
          this.selectedSettlementRunStatus as string,
          this.storedStartDate,
          this.storedEndDate
        ),
        this.currentPage + 1
      );
      this.settlementRunActivityStore.setPagesLoaded([
        ...this.pagesLoaded,
        this.currentPage
      ]);
    }
  }

  async handleNavigateToPreviousPage() {
    this.settlementRunActivityStore.setPaginationMetadata({
      ...(this.paginationMetadata as PaymentPaginationMetadata),
      page: this.currentPage - 1
    });
  }

  private setLocalDateTime(utcDate: string) {
    return utcDate ? utils.utcToLocalDateString(new Date(utcDate)) : "";
  }

  private formatMonetaryVal(value: number | string) {
    return formatMonetaryValue(value);
  }

  private async loadRemainingTableDataForExport(
    filters: PaymentFilterMenuSelection,
    page: number
  ) {
    // we don't update pagination metadata here so that the table remains on same page as when user clicked export
    const settlementRuns = await settlementRunActivityService.get(
      filters,
      page
    );
    this.settlementRunActivityStore.setSettlementRuns([
      ...this.settlementRuns,
      ...settlementRuns.data
    ]);
    this.settlementRunActivityStore.setPagesLoaded([...this.pagesLoaded, page]);
  }

  @Catch((error: any, context: any) => {
    context.fileToBeDownloadedName = "";
    context.handleExportResultsModal();
    context.isExportInProgress = false;
    context.$toasted.show(`<p>Error occurred</p>`, {
      ...options.ERROR_OPTIONS
    });
  })
  async handleExportResultsToCsv(fileName: string) {
    this.isExportInProgress = true;
    this.fileToBeDownloadedName = fileName;

    // load remaining pages' results
    const filters = new PaymentFilterMenuSelection(
      this.selectedCompany as string,
      this.selectedPaymentType as string,
      this.selectedSettlementRunStatus as string,
      this.storedStartDate,
      this.storedEndDate
    );
    const tasks: Promise<void>[] = [];
    for (let i = 1; i < this.totalPages + 1; i++) {
      if (!this.pagesLoaded.includes(i)) {
        tasks.push(this.loadRemainingTableDataForExport(filters, i));
      }
    }
    await Promise.all(tasks);

    downloadCsvFile(
      this.fileToBeDownloadedName,
      this.availableHeaders.filter(column => column.selected).map(h => h.text),
      this.availableHeaders.filter(column => column.selected).map(h => h.value),
      this.settlementRuns
    );

    this.fileToBeDownloadedName = "";
    this.handleExportResultsModal();
    this.isExportInProgress = false;
  }

  handleExportResultsModal() {
    this.isExportResultsModalVisible = !this.isExportResultsModalVisible;
  }

  navigateToSettlementRunDetail(settlementRun: SettlementRunActivityRecord) {
    this.settlementRunActivityStore.setSelectedSettlementRun(settlementRun);
  }

  handleReturnToSettlementRuns() {
    this.settlementRunActivityStore.setSelectedSettlementRun(null);
  }
}
