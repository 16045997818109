




















































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { utils } from "@/utils/okta-utils";
import Catch from "@/shared/decorators/catch-errors";
import options from "@/shared/constants/toast-options";
import { settlementRunActivityService } from "@/services/payments/settlement-run-activity.service";
import { TableColumnSelector } from "@/models/payments/table-column-selector";
import { SettlementRunActivityRecord } from "@/models/payments/settlement-run-activity/settlement-run-activity-record";
import { SettlementRunPaymentDetail } from "@/models/payments/settlement-run-activity/settlement-run-payment-details";
import { CancelSettlementRunResponse } from "@/models/payments/settlement-run-activity/cancel-settlement-run-response";
import { CancelSettlementRunRequest } from "@/models/payments/settlement-run-activity/cancel-settlement-run-request";
import { PaymentFilterMenuSelection } from "@/models/payments/payment-filter-menu-selection";
import { formatMonetaryValue } from "@/helpers/decimal-formatting-helper";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import SimpleConfirmationModal from "@/components/design-system/modals/SimpleConfirmationModal.vue";
import SettlementRunActivityStore from "@/store/payments/settlement-run-activity.store";

@Component({
  components: {
    "primary-button": PrimaryButton,
    "simple-confirmation-modal": SimpleConfirmationModal
  }
})
export default class SettlementRunDetails extends Vue {
  @Prop() settlementRun!: SettlementRunActivityRecord;
  private isLoading = false;
  private currentTime = Date.now();
  private isCancelConfirmationModalVisible = false;
  private paymentList: SettlementRunPaymentDetail[] = [];
  private paymentListHeaders: TableColumnSelector[] = [
    {
      align: "start",
      text: "Payee",
      sortable: false,
      value: "payee_name",
      selected: false
    },
    {
      align: "start",
      text: "Company",
      sortable: false,
      value: "company_name",
      selected: false
    },
    {
      align: "start",
      text: "Payment ID",
      sortable: false,
      value: "payment_uuid",
      selected: false
    },
    {
      align: "start",
      text: "Type",
      sortable: false,
      value: "payment_type",
      selected: false
    },
    {
      align: "end",
      text: "Total",
      sortable: false,
      value: "amount",
      selected: false
    }
  ];

  private readonly settlementRunActivityStore: SettlementRunActivityStore = getModule(
    SettlementRunActivityStore,
    this.$store
  );

  private get currentTimestamp(): number {
    return this.currentTime;
  }

  private get isPendingRunInDelayWindow(): boolean {
    return (
      this.settlementRun.payment_file_status_type == "Pending" &&
      Date.parse(this.settlementRun.release_at) > this.currentTimestamp
    );
  }

  private setLocalDateTime(utcDate: string) {
    return utcDate ? utils.utcToLocalDateString(new Date(utcDate)) : "";
  }

  private formatMonetaryVal(value: number | string) {
    return formatMonetaryValue(value);
  }

  cancelPendingSettlementRun() {
    this.currentTime = Date.now();
    if (this.isPendingRunInDelayWindow) {
      this.isCancelConfirmationModalVisible = !this
        .isCancelConfirmationModalVisible;
    } else {
      const message =
        "Failed to cancel settlement - payment file is outside the cancellation window";
      this.$toasted.show(`<p>${message}</p>`, options.ERROR_OPTIONS);
    }
  }

  handleCloseCancelModal() {
    if (this.isCancelConfirmationModalVisible) {
      this.isCancelConfirmationModalVisible = !this
        .isCancelConfirmationModalVisible;
    }
  }

  @Catch((error: any, context: any) => {
    context.isLoading = false;
    context.$toasted.show(`<p>Error occurred</p>`, {
      ...options.ERROR_OPTIONS
    });
  })
  async created() {
    this.isLoading = true;
    this.paymentList = await settlementRunActivityService.getDetails(
      this.settlementRun.payment_file_uuid
    );
    this.currentTime = Date.now();
    this.isLoading = false;
  }

  /* eslint-disable @typescript-eslint/camelcase */
  async cancelSettlementRun() {
    this.currentTime = Date.now();
    if (this.isPendingRunInDelayWindow) {
      const request: CancelSettlementRunRequest = new CancelSettlementRunRequest(
        this.settlementRun.payment_file_uuid
      );
      const cancelDetails: CancelSettlementRunResponse = await settlementRunActivityService.cancelSettlementRun(
        request
      );

      if (cancelDetails.message == "success") {
        this.settlementRun.payment_file_status_type =
          cancelDetails.payment_file_status_type == "USER_CANCELED"
            ? "User Canceled"
            : cancelDetails.payment_file_status_type;
        this.settlementRun.canceled_by = cancelDetails.canceled_by;
        this.settlementRun.canceled_at = cancelDetails.canceled_at;

        const filters: PaymentFilterMenuSelection = {
          firstItemSelection:
            this.settlementRunActivityStore.getSelectedCompany ?? "",
          secondItemSelection:
            this.settlementRunActivityStore.getSelectedPaymentType ?? "",
          thirdItemSelection:
            this.settlementRunActivityStore.getSelectedSettlementRunStatus ??
            "",
          startDate: this.settlementRunActivityStore.getStartDate,
          endDate: this.settlementRunActivityStore.getEndDate
        };

        this.$emit("updateTableAfterCancellation", filters);

        this.$toasted.show(
          "<p>Settlement run canceled</p>",
          options.SUCCESS_OPTIONS
        );
      } else {
        this.$toasted.show(
          `<p>${cancelDetails?.message ?? "Error Occured"}</p>`,
          options.ERROR_OPTIONS
        );
      }
    } else {
      const message =
        "Failed to cancel settlement - payment file is outside the cancellation window";
      this.$toasted.show(`<p>${message}</p>`, options.ERROR_OPTIONS);
    }
    this.handleCloseCancelModal();
  }

  @Watch("currentTime")
  calculateTime() {
    setTimeout(() => {
      this.currentTime = Date.now();
    }, 10000); // Update every 10 seconds
  }
}
